:root {
  --menu-color: #fefefe;
}

@font-face {
  font-family: "customFont";
  src: url("../CenturyGothic.ttf");
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: var(--menu-color);
  height: 40px;
  padding: 0 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 100%;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-button {
  cursor: pointer;
  margin-left: auto;
  color: #898b8e;
  margin-right: 30px;
  transform: translateX(10px);
  border-radius: 10px;
}

.navbar-button:hover {
  color: #a2a19d;

}
.navbar-button:active {
  background-color: rgba(0, 0, 0, 0.2);
  color: #82817d;
}

.navbar-title {
  font-size: 24px;
  margin: 0;
  margin-left: 50px;
  color: #000000;
  font-family: "customFont", Arial, Helvetica, sans-serif;
  -webkit-transform: translate3d(0, 0, 1px);
  -moz-transform: translate3d(0, 0, 1px);
  -ms-transform: translate3d(0, 0, 1px);
  -o-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}

.navbar-title::selection {
  background-color: transparent;
}

.navbar-map {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  cursor: pointer;
  margin-right: 250px;
  color: #000;
}

.navbar-map:hover {
  color: #fff;
}

.navbar-map:active {
  color: #ddd;
  background-color: rgba(0, 0, 0, 0.2);
}



.title-popup {
  margin-top: 10px;
}

.popovertrigger {
  position: relative;
  display: inline-block;
  background-color: transparent;
}

.layerbutton {
  background-color: transparent;
  border: 2px solid;
  padding: 10px;
  outline: none;
  cursor: pointer;
  margin-left: auto;
  margin-right: 30px;
  transform: translateX(10px);
}

.layerbutton:hover {
  color: rgba(0, 0, 0, 0.1);
}

.layerbutton:active {
  color: #ddd;
  background-color: rgba(0, 0, 0, 0.2);
}

.numbeo-link {
  color: blue;
  text-decoration: underline;
}

.about-button {
  font-family: "customFont", Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #000000;
  padding: 5px;
  border-radius: 10px;
}

.about-button:hover {
  color: #484848;
  background-color: rgba(0, 0, 0, 0.05);
}
.about-button:active {
  color: #ddd;
}

.about-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 520px;
  height: 505px;
  background-color: #dfdfdf;
  border: 1px solid black;
  padding: 10px;
  z-index: 999;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
}

#about-title {
  font-family: "customFont", Arial, Helvetica, sans-serif;
  font-size: 24px;
  color: #000000;
  margin: 0;
  margin-bottom: 10px;
  text-align: left;
}

#about-subtitle {
  font-family: "customFont", Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #ededed;
  margin: 0;
  margin-bottom: 10px;
  text-align: left;
}

#close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
}

#close-button:hover {
  color: #b4b4a9;
  background-color: rgba(0, 0, 0, 0.05);
}

#close-button:active {
  color: #aaa;
}

#website-overview {
  margin-bottom: 5px;
}

#brexit-warning {
  margin-bottom: 5px;
}

#data-source {
  margin-bottom: 5px;
}

#grant-info {
  margin-bottom: 5px;
}

#website-creator {

}

#numbeo-link {
  color: blue;
}