:root {
  --menu-color: #5d5d5d;
}

@font-face {
  font-family: "customFont";
  src: url("./CenturyGothic.ttf");
}

.map-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.popup-container {
  width: 100%;
  height: 100%;
  position: fixed;
  margin-top: 20px;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup {
  width: 520px;
  height: 505px;
  background-color: #f9f9f9;
  border: 1px solid black;
  padding: 10px;
  z-index: 999;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-left: 4px;
  margin-right: 4px;
  color: rgba(0, 0, 0, 0.6);
}

.close-button:hover {
  color: #000;
  background-color: rgba(0, 0, 0, 0.05);
}

.sidebar {
  display: flex;
  position: fixed;
  left: -200px;
  top: 40px;
  bottom: 0;
  width: 200px;
  border-right: 2px solid;
  border-color: rgba(0, 0, 0, 0.2);
  background-color: #f9f9f9;
  z-index: 9;
  transition: all 0.3s ease;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-transform: translate3d(0, 0, 1px);
  -moz-transform: translate3d(0, 0, 1px);
  -ms-transform: translate3d(0, 0, 1px);
  -o-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}

.sidebar-open {
  transform: translateX(200px);
}

.sidebar2 {
  position: fixed;
  top: 40px;
  right: 0;
  bottom: 0;
  width: 300px;
  background-color: #f9f9f9;
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  z-index: 10;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}


.searchbar {
  border: 2px solid;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-left: 4px;
  text-align: left;
  background-color: #f9f9f9;
  text-indent: 10px;
  color: #fff;
  outline: none;
}

.sidebar-content {
  padding: 10px;
  font-family: "customFont", sans-serif;
}

.city-button {
  color: #eaeaea;
}

.city-button:hover {
  color: #fff;
}

/* For all WebKit-based browsers */
::-webkit-scrollbar {
  width: 10px; /* Set the width of the scrollbar */
}

/* For the track of the scrollbar */
::-webkit-scrollbar-track {
  background-color: var(--menu-color); /* Set the background color */
}

/* For the handle of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the handle */
  border-radius: 10px;
}

/* For the corner of the scrollbar */
::-webkit-scrollbar-corner {
  background-color: #f1f1f1; /* Set the color of the corner */
}

.city-popup-title {
  font-size: 21px;
  margin: 0;
  margin-bottom: 10px;
  font-family: "customFont", Arial, Helvetica, sans-serif;
  justify-content: center;
  text-align: center;
}

.cityRank {
  font-size: 18px;
  margin: 0;
  margin-bottom: 10px;
  font-family: "customFont", Arial, Helvetica, sans-serif;
  justify-content: center;
  text-align: left;
}

.descriptionTitle {
  font-size: 16px;
  font-style: bold;
  margin: 0;
  margin-bottom: 10px;
  font-family: "customFont", Arial, Helvetica, sans-serif;
  justify-content: center;
  text-align: left;
}

.priceTitle {
  font-size: 16px;
  font-style: bold;
  margin: 0;
  margin-bottom: 10px;
  font-family: "customFont", Arial, Helvetica, sans-serif;
  justify-content: center;
  text-align: right;
  padding: 10px;
}

.rankingTitle {
  font-size: 16px;
  font-style: bold;
  margin: 0;
  margin-bottom: 10px;
  font-family: "customFont", Arial, Helvetica, sans-serif;
  justify-content: center;
  text-align: right;
}

.itemDescription {
  font-size: 14px;
  margin: 0;
  margin-bottom: 10px;
  font-family: "customFont", Arial, Helvetica, sans-serif;
  justify-content: center;
  text-align: left;
}

.itemPrice {
  font-size: 14px;
  margin: 0;
  margin-bottom: 10px;
  font-family: "customFont", Arial, Helvetica, sans-serif;
  justify-content: right;
  text-align: right;
  padding-right: 10px;
}

.itemRank {
  font-size: 14px;
  margin: 0;
  margin-bottom: 10px;
  font-family: "customFont", Arial, Helvetica, sans-serif;
  justify-content: right;
  text-align: right;
}

.grantGroup {
  font-size: 16px;
  font-style: bold;
  margin: 0;
  margin-top: 10px;
  font-family: "customFont", Arial, Helvetica, sans-serif;
  justify-content: center;
  text-align: left;
  
}

.resetButton {
  font-size: 16px;
  margin: 4px;
  font-style: bold;
  font-family: "customFont", Arial, Helvetica, sans-serif;
  justify-content: center;
  text-align: right;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  z-index: 999;
  border:rgba(0, 0, 0, 0.2) 0.5px solid;
  background-color: #ec9184;
  border-radius: 7px;
}

.resetButton:hover {
  background-color: #f38779;
}

.resetButton:active {
  background-color: #d7796c;  
}

table {
  border-collapse: collapse;
  width: 100%;
}

thead tr th, tbody tr td {
  padding: 8px;
}

tbody tr td {
  border-top: 2px solid #000000;
  border-bottom: 2px solid #000000;
}

